<template>
    <grid-view 
        title="Темы"
        filter
        create
        create-title="Добавить тему"
        url-create="/theme/create"
        store-module="theme"
        :headers="[
            { text: 'ID', value: 'id', width: 1 },
            { text: 'Название', value: 'name', width: 1, sortable: false }
        ]"
    >
        <!-- Filter -->
        <template v-slot:filter="{ filter }">
            
            <v-row>
                <v-col sm="12">
                    <v-text-field 
                        v-model="filter.name"
                        label="Название" 
                        type="text" 
                        placeholder=" "
                        :color="$const.color.primary" 
                    ></v-text-field>
                </v-col>
            </v-row>
        </template>
        <!-- END Filter -->
    </grid-view>
</template>
<script>
import GridView from '@/components/crud/GridView.vue'

export default {
    name: 'ThemeIndex',
    components: { GridView }
}
</script>